import Image from "next/image";
import gridleyLogo from "../../public/img/gridley-flattened.png";
import { HowToPlayLink } from "./buttons/HowToPlayLink";
import Link from "next/link";
import { LogoStrip } from "./LogoStrip";
import AdSlot from "./AdSlot";

export function Footer() {
  return (
    <>
      <div className="sticky bottom-2 left-0 right-0 mx-auto mb-4 mt-24 h-[50px] max-h-[50px] max-w-screen-lg text-center sm:h-[90px] sm:max-h-[90px] md:mb-12 md:mt-20">
        <AdSlot />
      </div>
      <footer className="border-t border-t-slate-200 bg-white px-4 py-8 dark:bg-gray-800 md:py-8">
        <div className="mx-auto max-w-screen-xl text-center">
          {/* <a href="https://gridleygame.com/" className="mb-2 block">
            <Image
              src={gridleyLogo}
              alt="Gridley Game Logo"
              className="grayscale"
              layout="intrinsic"
              width={115}
              height={24}
            />
          </a> */}
          {/* <h1 className="mb-8 text-sm text-black dark:text-gray-400">
            Gridley - The daily footy grid game for AFL trivia fans
          </h1> */}
          <ul className="mb-6 flex flex-wrap items-center justify-center space-x-8 text-sm text-black dark:text-white md:mb-8 md:space-x-10 md:text-base">
            <li>
              <HowToPlayLink />
            </li>
            <li>
              <Link href="/privacy" passHref>
                <a className="hover:underline">Privacy Policy</a>
              </Link>
            </li>
            <li>
              <a
                className="hover:underline"
                href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#116;&#101;&#97;&#109;&#64;&#103;&#114;&#105;&#100;&#108;&#101;&#121;&#103;&#97;&#109;&#101;&#46;&#99;&#111;&#109;"
              >
                Contact
              </a>
            </li>
          </ul>
          <div className="mb-6 mt-4 flex justify-center space-x-5 md:mb-8">
            <a
              href="https://twitter.com/GridleyGame"
              target="_blank"
              className="text-gray-700 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="h-7 w-7 md:h-8 md:w-8"
                aria-hidden="true"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>

              <span className="sr-only">Twitter page</span>
            </a>
            <a
              href="https://www.tiktok.com/@gridleygame"
              target="_blank"
              className="text-gray-700 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="h-7 w-7 md:h-8 md:w-8"
                viewBox="0 0 512 512"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z" />
              </svg>
              <span className="sr-only">TikTok account</span>
            </a>
          </div>
          <h1 className="mb-0 text-sm text-black dark:text-gray-400">
            Gridley - The daily footy grid game for AFL trivia fans
          </h1>
        </div>
      </footer>
      <LogoStrip />
    </>
  );
}
